import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Loader from "./Components/Screen/Loader/Loader";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ProtectedRoutes from "./Components/Authentications/Others/ProtectedRoutes";
import { UserAuthContextProvider } from "./Components/Authentications/Others/AuthContext";
import ScrollToTop from "./Assets/ScrollTop";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import { readNotification } from "./Components/HttpServices/teamManagerHttpServices/teamManagerHttpServices";
const MatchDraw = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/matchDraw")
);
const LeagueResults = lazy(() =>
  import("./Components/TeamManager/League/results")
);
const ResultsDetails = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/results")
);

const ManageAlumni = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/manageAlumni")
);
const ViewRecording = lazy(() =>
  import("./Components/TeamManager/Coach/viewRecording")
);
const FeedbackFormView = lazy(() =>
  import("./Components/Coach/FeedbackManagement/FeedbackFormView")
);
const BannerDetails = lazy(() =>
  import("./Components/TeamManager/HomeManagerComponents/bannerDetails")
);

const Games = lazy(() => import("./Components/TeamManager/MyGames/Games"));
const GamesTournament = lazy(() =>
  import("./Components/TeamManager/MyGames/GamesTournament")
);
const UpcomingGames = lazy(() =>
  import("./Components/TeamManager/MyGames/UpcomingGames")
);
const PastGames = lazy(() =>
  import("./Components/TeamManager/MyGames/pastGames")
);

const CoachList = lazy(() =>
  import("./Components/TeamManager/Coach/CoachList")
);
const CoachBookedSessions = lazy(() =>
  import("./Components/TeamManager/Coach/CoachBookedSessions")
);

const CertificatesList = lazy(() =>
  import("./Components/TeamManager/Certificates/CertificatesList")
);
const ViewCertificate = lazy(() =>
  import("./Components/TeamManager/Certificates/viewCertificate")
);
const CoachChessboard = lazy(() =>
  import("./Components/Coach/ChessBoard/CoachChessboard")
);

// Common Routes
const Language = lazy(() => import("./Components/Screen/Language"));
const UserType = lazy(() => import("./Components/Screen/UserType"));
// Registration Routes
const StudentLogin = lazy(() =>
  import("./Components/Authentications/Login/StudentLogin")
);
const SendFeedback = lazy(() =>
  import("./Components/Coach/SlotManagement/SendFeedback")
);

const CoachLogin = lazy(() =>
  import("./Components/Authentications/Login/CoachLogin")
);
const TeamManagerLogin = lazy(() =>
  import("./Components/Authentications/Login/TeamManager")
);
const AluminiLogin = lazy(() =>
  import("./Components/Authentications/Login/AluminiLogin")
);
//Login Routes

const StudentRegister = lazy(() =>
  import("./Components/Authentications/Registration/StudentRegister")
);

const TeamManagerRegister = lazy(() =>
  import("./Components/Authentications/Registration/TeamManager")
);
const AluminiRegister = lazy(() =>
  import("./Components/Authentications/Registration/AluminiRegister")
);

const StudentHome = lazy(() => import("./Components/Student/Home"));
const StudentPlayWithFriends = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithFriends/PlayWithFriends")
);

const TeamManagerHome = lazy(() => import("./Components/TeamManager/Home"));
const AluminiHome = lazy(() => import("./Components/Aluminia/Home"));
const CoachHome = lazy(() => import("./Components/Coach/Home"));
const ContactUs = lazy(() =>
  import("./Components/Coach/ProfileComponent/ContactUs")
);

const ClubLeaderBoard = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/ClubLeaderBoard")
);
const ClubTournament = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/ClubTournaments")
);
const HostoricalLeaderBoard = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/HistoricalLeaderBoard")
);
const AlumniClubMembers = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/AlumniClubMembers")
);
const Club_LeaderBoard = lazy(() =>
  import(
    "./Components/TeamManager/LeaderBoard/Club Tournaments/ClubLeaderboard"
  )
);
const Club_Tournaments = lazy(() =>
  import(
    "./Components/TeamManager/LeaderBoard/Club Tournaments/ClubTournaments"
  )
);
const PlayerBoard = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithFriends/PlayerBoard")
);
const ViewJoinedTournament = lazy(() =>
  import(
    "./Components/TeamManager/LeaderBoard/Club Tournaments/ViewJoinedTournament"
  )
);
const StudentClubTournament = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubTournaments/ClubTournament"
  )
);
const StudentClubLeaderBoard = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubTournaments/ClubLeaderBoard"
  )
);
const AlumniClubLeaderBoard = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/ClubLeaderBoard"
  )
);
const StudentViewJoined = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubTournaments/ViewJoinedTournament"
  )
);
const StudentNots = lazy(() =>
  import("./Components/Coach/StudentManagement/StudentNots")
);
const AttendanceList = lazy(() =>
  import("./Components/Coach/StudentManagement/AttendanceList")
);

const AlumniViewJoined = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/ViewJoinedTournament"
  )
);
const StudentPayment = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubTournaments/TournamentPayment"
  )
);
const AlumniPayment = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/TournamentPayment"
  )
);
const StudentIndividual = lazy(() =>
  import("./Components/Student/Coaching/IndividualCoach/IndividualCoaching")
);
const ViewStudentBookedSession = lazy(() =>
  import("./Components/Student/Coaching/IndividualCoach/ViewBookedSession")
);
const CoachingPayment = lazy(() =>
  import("./Components/Student/Coaching/IndividualCoach/CoachingPayment")
);

const AlumniIndividual = lazy(() =>
  import("./Components/Aluminia/Coaches/IndividualCoach/IndividualCoaching")
);
const ViewAlumniBookedSession = lazy(() =>
  import("./Components/Aluminia/Coaches/IndividualCoach/ViewBookedSession")
);
const AlumniCoachingPayment = lazy(() =>
  import("./Components/Aluminia/Coaches/IndividualCoach/CoachingPayment")
);

const StudentClubResult = lazy(() =>
  import("./Components/Student/PlayerTournaments/ClubTournaments/ClubResults")
);
const AlumniClubResult = lazy(() =>
  import("./Components/Aluminia/AlumniTournaments/ClubTournaments/ClubResults")
);
const StudentPrizeDistribution = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubTournaments/PrizeDistribution"
  )
);
const AlumniPrizeDistribution = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/PrizeDistribution"
  )
);
const StudentLeague = lazy(() =>
  import("./Components/Student/PlayerTournaments/ClubLeague/LeagueCity")
);
const StudentLeagueResults = lazy(() =>
  import("./Components/Student/PlayerTournaments/ClubLeague/Results")
);
const AlumniPrizeLeagueDistribution = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/PrizeDistribution"
  )
);
const AlumniManagement = lazy(() =>
  import("./Components/Coach/StudentManagement/AlumniManagement")
);
const StudentPrizeLeagueDistribution = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubLeague/PrizeLeagueDistribution"
  )
);

const StudentLeagueLeaderBoard = lazy(() =>
  import("./Components/Student/PlayerTournaments/ClubLeague/LeagueLeaderBoard")
);
const ManagerClubPayment = lazy(() =>
  import("./Components/TeamManager/LeaderBoard/Club Tournaments/ClubPayment")
);
const StudentLeaguePayment = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubLeague/LeagueParts/LeaguePayment"
  )
);
const StudentJoinedLeague = lazy(() =>
  import("./Components/Student/PlayerTournaments/ClubLeague/JoinedLeague")
);
const PrizePool = lazy(() =>
  import("./Components/TeamManager/Prize/ClubTournament/PrizePoolClub")
);
const PrizePoolCard = lazy(() =>
  import("./Components/TeamManager/Prize/ClubTournament/PrizePoolCard")
);
const PrizePoolSponsor = lazy(() =>
  import("./Components/TeamManager/Prize/ClubTournament/PrizePoolSponsor")
);

const SponsorPrizePayment = lazy(() =>
  import("./Components/TeamManager/SponsorFlow/SponsorPayment")
);

const SponsorLeaguePrizePayment = lazy(() =>
  import("./Components/TeamManager/SponsorFlow/SponsorLeaguePayment")
);
const PizePooLeague = lazy(() =>
  import("./Components/TeamManager/Prize/LeagueTournament/PrizeLeague")
);
const LeaguePrizePool = lazy(() =>
  import("./Components/TeamManager/Prize/LeagueTournament/PrizePoolCard")
);
const LeaguePrizePoolSponsor = lazy(() =>
  import("./Components/TeamManager/Prize/LeagueTournament/PrizePoolSponsor")
);
const CoachStudentManagement = lazy(() =>
  import("./Components/Coach/StudentManagement/StudentManagement")
);
const CoachEarningManagement = lazy(() =>
  import("./Components/Coach/EarningManagement/EarningManagement")
);
const CoachSlotManagement = lazy(() =>
  import("./Components/Coach/SlotManagement/SlotManagement")
);
const CoachFeedbackManagement = lazy(() =>
  import("./Components/Coach/FeedbackManagement/FeedbackManagement")
);
const CoachSchoolManagement = lazy(() =>
  import("./Components/Coach/StudentManagement/SchoolManagement")
);
const TournamentSchedule = lazy(() =>
  import(
    "./Components/TeamManager/LeaderBoard/Club Tournaments/ScheduleMatches"
  )
);
const StudentTournamentSchedule = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubTournaments/ClubScheduleMatches"
  )
);
const AlumniTournamentSchedule = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/ClubScheduleMatches"
  )
);
const CoachPayment = lazy(() =>
  import("./Components/TeamManager/Coach/CoachPaymentt")
);
const Chat = lazy(() => import("./Components/Coach/chatSection/Chat"));
const VideoConference = lazy(() =>
  import("./Components/Coach/chatSection/VideoConference")
);
const StudentLeagueSchedule = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubLeague/LeagueScheduleMatches"
  )
);
const TeamManagerLeagueSchedule = lazy(() =>
  import("./Components/TeamManager/League/LeagueParts/LeagueScheduleMatches")
);
const StudentPlayWithAlumni = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithAlumni/PLayWithAlumni")
);

const StudentPlayWithAlumniResults = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithAlumni/Results")
);
const TeamManagerLeague = lazy(() =>
  import("./Components/TeamManager/League/League")
);
const TeamManagerLeagueLeaderBoard = lazy(() =>
  import("./Components/TeamManager/League/LeagueLeaderBoard")
);
const TeamManagerJoinedLeague = lazy(() =>
  import("./Components/TeamManager/League/JoinedLeague")
);
const MyTeam = lazy(() => import("./Components/TeamManager/Team/MyTeam"));
const StudentRequest = lazy(() =>
  import("./Components/TeamManager/Team/StudentRequest")
);
const ManagerLeaguePayment = lazy(() =>
  import("./Components/TeamManager/League/LeagueParts/LeaguePayment")
);
const ManagerLeaguePrize = lazy(() =>
  import("./Components/TeamManager/League/LeagueParts/LeaguePrize")
);
const AlumniTornament = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/ClubTournament"
  )
);
const StudentPlayWithBot = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithBot/PlayWithBot")
);
const StudentPlayWithBotExtended = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithBot/PlayWithBotExtended")
);
const AlumniPlayWithBot = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithBot/PlayWithBot")
);
const AlumniPlayWithMatches = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithFriends/PlayWithMatches")
);
const AlumniStudentPlayWithMatches = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithAlumni/Matches")
);
const StudentPlayWithMatches = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithFriends/Matches")
);
const AlumniPlayWitheResult = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithFriends/PlayWithResults")
);
const StudentPlayWitheResult = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithFriends/Results")
);
const AlumniPlayWithFriends = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithFriends/PlayWithFriends")
);

const AlumniAllFriends = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithFriends/AllFriends")
);

const StudentAllAlumni = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithAlumni/AllFriends")
);
const AlumniWithAllFriends = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithAlumni/AllFriends")
);
const AlumniWithPlayFriends = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithAlumni/PLayWithAlumni")
);
const AlumniWithMatches = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithAlumni/Matches")
);
const AlumniWithResults = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithAlumni/Results")
);

const StudentAllFriends = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithFriends/AllFriends")
);
const CoachMyProfile = lazy(() =>
  import("./Components/Coach/ProfileComponent/MyProfile")
);
const CoachSettings = lazy(() =>
  import("./Components/Coach/ProfileComponent/Settings")
);
const CoachSupportQuery = lazy(() =>
  import("./Components/Coach/ProfileComponent/HelpSupport")
);
const CoachHelpandSupport = lazy(() =>
  import("./Components/Coach/ProfileComponent/HelpSupport")
);
const AlumniPlayWithChessBoard = lazy(() =>
  import(
    "./Components/Aluminia/PlayerGames/PlayWithFriends/PlayerBoard/PlayerChessBoard"
  )
);
const AlumniSession = lazy(() =>
  import("./Components/Coach/SlotManagement/AlumniSession")
);
const TeamSession = lazy(() =>
  import("./Components/Coach/SlotManagement/TeamSession")
);

const IndividualSession = lazy(() =>
  import("./Components/Coach/SlotManagement/IndividualSession")
);

const AlumniPlayerBotExtended = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithBot/PlayerBotExtended")
);
const AlumniMyProfile = lazy(() =>
  import("./Components/Aluminia/ProfileComponents/MyProfile")
);
const AlumniSettings = lazy(() =>
  import("./Components/Aluminia/ProfileComponents/Settings")
);
const AlumniSubscription = lazy(() =>
  import("./Components/Aluminia/ProfileComponents/Subscriptions")
);
const StudentSubscription = lazy(() =>
  import("./Components/Student/ProfileComponents/Subscriptions")
);
const AlumniSupport = lazy(() =>
  import("./Components/Aluminia/ProfileComponents/HelpSuppport")
);

const AddAlumniSupport = lazy(() =>
  import("./Components/Aluminia/ProfileComponents/AddFeedback")
);

const AddStudentSupport = lazy(() =>
  import("./Components/Student/ProfileComponents/AddFeedback")
);
const AddCoachSupport = lazy(() =>
  import("./Components/Coach/ProfileComponent/AddFeedback")
);
const StudentSupport = lazy(() =>
  import("./Components/Student/ProfileComponents/HelpSuppport")
);
const ManagerAddSupport = lazy(() =>
  import("./Components/TeamManager/ProfileComponents/AddFeedback")
);
const AlumniChessRating = lazy(() =>
  import("./Components/Aluminia/ProfileComponents/ChessRating")
);

const StudentChessRating = lazy(() =>
  import("./Components/Student/ProfileComponents/MyChessRating")
);
const Alumni_Analysis = lazy(() =>
  import("./Components/Aluminia/Analysis/Analysis")
);
const Alumni_Coaching = lazy(() =>
  import("./Components/Aluminia/Coaches/Coaches")
);
const Alumni_Records = lazy(() =>
  import("./Components/Aluminia/Records/Records")
);
const Student_Records = lazy(() =>
  import("./Components/Student/Records/Records")
);
const Alumni_Certificate = lazy(() =>
  import("./Components/Aluminia/Certificates/Certficates")
);
const Alumni_Prize = lazy(() =>
  import("./Components/Aluminia/Certificates/Prizes")
);

const Alumni_ViewCertificate = lazy(() =>
  import("./Components/Aluminia/Certificates/ViewCertificates")
);

const Student_MyProfile = lazy(() =>
  import("./Components/Student/ProfileComponents/MyProfile")
);
const ManagerSupport = lazy(() =>
  import("./Components/TeamManager/ProfileComponents/HelpSuppport")
);
const ManagerProfile = lazy(() =>
  import("./Components/TeamManager/ProfileComponents/MyProfile")
);
const BackupManager = lazy(() =>
  import("./Components/TeamManager/ProfileComponents/BackupManager")
);
const Settings = lazy(() =>
  import("./Components/TeamManager/ProfileComponents/Settings")
);
const PushNotification = lazy(() =>
  import("./Components/TeamManager/ProfileComponents/PushNotification")
);

const About = lazy(() => import("./Components/Screen/Common/About"));
const Terms = lazy(() =>
  import("./Components/Screen/Common/TermsandCondition")
);
const Privacy = lazy(() => import("./Components/Screen/Common/PrivacyPolicy"));
const entities = ["Student", "Alumni", "Coach", "TeamManager"];
const ManagerCoachingDetails = lazy(() =>
  import("./Components/TeamManager/Team/CoachingAttendance")
);
const AlumniMyPayments = lazy(() =>
  import("./Components/Aluminia/ProfileComponents/MySubscription")
);
const MakeAlumniPaymwent = lazy(() =>
  import(
    "./Components/Aluminia/ProfileComponents/SubscriptionScreens/CreateSubscription"
  )
);
const StudentMyPayments = lazy(() =>
  import("./Components/Student/ProfileComponents/MySubscription")
);
const MakeStudentPaymwent = lazy(() =>
  import(
    "./Components/Student/ProfileComponents/SubscriptionScreens/CreateSubscription"
  )
);
const BotScheduleMatches = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithBot/ScheduleMatches")
);

const StudentLeaderBoard = lazy(() =>
  import("./Components/Student/HomeStudentComponents/LeaderBoard")
);
const AlumniLeaderBoard = lazy(() =>
  import("./Components/Aluminia/AlumniHomeComponents/LeaderBoard")
);
const StudentHistoricalLeaderBoard = lazy(() =>
  import("./Components/Student/HomeStudentComponents/HistoricalLeaderBoard")
);
const AlumniHistoricalLeaderBoard = lazy(() =>
  import("./Components/Aluminia/AlumniHomeComponents/HistoricalLeaderBoard")
);
const StudentCoaching = lazy(() =>
  import("./Components/Student/Coaching/Coaching")
);
const StudentCoachingSession = lazy(() =>
  import("./Components/Student/Coaching/CoachingSeason")
);
const StudentUpcomingCoachingSession = lazy(() =>
  import("./Components/Student/Coaching/UpcomingSession")
);
const StudentAnalytics = lazy(() =>
  import("./Components/Student/Analytics/Analytics")
);
const StudentCertficates = lazy(() =>
  import("./Components/Student/Certificates/Certficates")
);
const StudentPrizes = lazy(() =>
  import("./Components/Student/Certificates/Prizes")
);
const StudentSettings = lazy(() =>
  import("./Components/Student/ProfileComponents/Settings")
);

const StudentOpeningAnalytics = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithBot/OpeningAnalytics")
);
const AlumniScheduleMatches = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithBot/ScheduleMatches")
);

const StudentAllCertificates = lazy(() =>
  import("./Components/Student/Certificates/AllCertificates")
);
const AlumniAllCertificates = lazy(() =>
  import("./Components/Aluminia/Certificates/AllCertificates")
);
const StudentViewCertificates = lazy(() =>
  import("./Components/Student/Certificates/ViewCertificates")
);
const AlumniViewCertificates = lazy(() =>
  import("./Components/Aluminia/Certificates/ViewCertificates")
);
const AlumniCoachingSession = lazy(() =>
  import("./Components/Aluminia/Coaches/CoachingSeason")
);
const AlumniUpcomingCoachingSession = lazy(() =>
  import("./Components/Aluminia/Coaches/UpcomingSession")
);
const MatchDrawResults = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubTournaments/MatchDrawResults"
  )
);
const MatchAlumniDrawResults = lazy(() =>
  import(
    "./Components/Aluminia/AlumniTournaments/ClubTournaments/MatchDrawResults"
  )
);
const MatchLeagueDrawResults = lazy(() =>
  import(
    "./Components/Student/PlayerTournaments/ClubLeague/MatchLeagueDrawResults"
  )
);
const TeamManagerSponsor = lazy(() =>
  import("./Components/TeamManager/SponsorFlow/SponsorPage")
);
const TeamManagerLeagueSponsor = lazy(() =>
  import("./Components/TeamManager/SponsorFlow/SponsorLeague")
);
const StudentNotifcationWrap = lazy(() =>
  import("./Components/Student/HomeStudentComponents/NotificationWrap")
);
const AlumniNotifcationWrap = lazy(() =>
  import("./Components/Aluminia/AlumniHomeComponents/NotificationWrap")
);
const StudentUpgradeSubscription = lazy(() =>
  import(
    "./Components/Student/ProfileComponents/SubscriptionScreens/UpgradeSubscription"
  )
);
const AlumniUpgradeSubscription = lazy(() =>
  import(
    "./Components/Aluminia/ProfileComponents/SubscriptionScreens/UpgradeSubscription"
  )
);
const BotResults = lazy(() =>
  import("./Components/Student/PlayerGames/PlayWithBot/BotResults")
);
const AlumniBotResults = lazy(() =>
  import("./Components/Aluminia/PlayerGames/PlayWithBot/BotResults")
);
const StudentLiveTournaments = lazy(() =>
  import(
    "./Components/Student/HomeStudentComponents/LiveTournaments/LiveTournaments"
  )
);
const AlumniLiveTournaments = lazy(() =>
  import(
    "./Components/Aluminia/AlumniHomeComponents/LiveTournaments/LiveTournaments"
  )
);
const AlumniSendFeedback = lazy(() =>
  import("./Components/Aluminia/Coaches/IndividualCoach/SendFeedback")
);
const StudentLiveTournamentsDetails = lazy(() =>
  import(
    "./Components/Student/HomeStudentComponents/LiveTournaments/LiveTournamentDetails"
  )
);
const AlumniLiveTournamentsDetails = lazy(() =>
  import(
    "./Components/Aluminia/AlumniHomeComponents/LiveTournaments/LiveTournamentDetails"
  )
);
const StudentLiveLeagues = lazy(() =>
  import(
    "./Components/Student/HomeStudentComponents/LiveTournaments/LiveLeagues"
  )
);
const SessionFeedback = lazy(() =>
  import("./Components/Student/Coaching/SendFeedback")
);
const ViewSessionFeedback = lazy(() =>
  import("./Components/Student/Coaching/ViewFeedback")
);
const StudentLiveLeagueDetails = lazy(() =>
  import(
    "./Components/Student/HomeStudentComponents/LiveTournaments/LiveLeaguesDetails"
  )
);

const StudentLiveDraw = lazy(() =>
  import(
    "./Components/Student/HomeStudentComponents/LiveTournaments/LiveMatchDraw"
  )
);
const StudentRecordChessBoard = lazy(() =>
  import("./Components/Student/Records/ChessBoard/ChessBoard")
);
const AlumniRecordChessBoard = lazy(() =>
  import("./Components/Aluminia/Records/ChessBoard/ChessBoard")
);
const LiveBoard = lazy(() =>
  import(
    "./Components/Student/HomeStudentComponents/LiveTournaments/LiveBoard/LiveBoard"
  )
);
const AlumniLiveBoard = lazy(() =>
  import(
    "./Components/Aluminia/AlumniHomeComponents/LiveTournaments/LiveBoard/LiveBoard"
  )
);

const ManagerScheduleDetails = lazy(() =>
  import("./Components/TeamManager/SponsorFlow/ScheduleDetails")
);
const PaymentSuccess = lazy(() =>
  import("./Components/Screen/Common/PaymentSuccess")
);
const PaymentFailed = lazy(() =>
  import("./Components/Screen/Common/PaymentFailed")
);
function App() {
  const { i18n } = useTranslation();

  const config = {
    apiKey: "AIzaSyBSZ2Dk1ztdqMq2qPXTmR9TqHQ7CxCBtHU",
    authDomain: "mind-league.firebaseapp.com",
    projectId: "mind-league",
    storageBucket: "mind-league.firebasestorage.app",
    messagingSenderId: "237785394353",
    appId: "1:237785394353:web:7b1f7c134fca70157a8690",
    measurementId: "G-HZVVW5D5N5",
  };

  useEffect(() => {
    setTimeout(() => {
      import("firebase/messaging").then(
        ({ getMessaging, getToken, onMessage, isSupported }) => {
          const app = initializeApp(config);
          const messaging = getMessaging(app);
          isSupported().then((support) => {
            console.log(support);
            if (support && Notification.permission === "granted") {
              getFirebaseToken(getMessaging(app));
            }
          });
          const getFirebaseToken = () => {
            getToken(messaging, {
              vapidKey:
                "BAzUwkGoespwwsN_vXXwPITlXHlDW_3thdG6ZS0x6erHynKS3422MGNW0ne9cbOydr-Yv-o6FkmU7-Xqhiftxx4",
            })
              .then((currentToken) => {
                if (currentToken) {
                  console.log(currentToken);
                  localStorage.setItem("fcmTokenMl", currentToken);
                } else {
                  console.log(
                    "No registration token available. Request permission to generate one."
                  );
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
              });
          };
          onMessage(messaging, (payload) => {
            console.log(payload);
            const options = {
              body: payload.notification.body,
              icon: payload.notification.icon,
              image: payload.notification.image,
              dir: i18n.language === "he" ? "rtl" : "ltr",
              vibrate: true,
              silent: false,
            };
            let notification = new Notification(
              payload.notification.title,
              options
            );

            notification.onclick = function (event) {
              event.preventDefault();
              const link = document.createElement("a");
              link.href = payload.fcmOptions.link;
              link.click();

              redirect(payload.data.notificationId);
            };

            localStorage.setItem(
              "notii",
              localStorage.getItem("notii") === "true" ? "false" : "true"
            );
          });
        }
      );
    }, 500);

    if (!localStorage.getItem("uidMl")) {
      localStorage.setItem("uidMl", uuidv4());
    }
  }, []);

  const redirect = async (id) => {
    const { data } = await readNotification(id);
  };

  return (
    <div dir={i18n.language === "he" ? "rtl" : "ltr"}>
      <Router>
        <ScrollToTop />
        <UserAuthContextProvider>
          <ScrollToTop />
          <Routes>
            {/* Common Routes */}

            <Route
              path="/Student/BotScheduleMatches"
              // path="/Alumni/AlumniWithNewStudents"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <BotScheduleMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="*"
              element={
                <Suspense fallback={<Loader />}>
                  <UserType />
                </Suspense>
              }
            />

            <Route
              path="/language"
              element={
                <Suspense fallback={<Loader />}>
                  <Language />
                </Suspense>
              }
            />

            {/* RegisterRoutes */}
            <Route
              path="/Student/Registration"
              element={
                <Suspense fallback={<Loader />}>
                  <StudentRegister />
                </Suspense>
              }
            />
            <Route
              path="/Student/LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/HistoricalLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentHistoricalLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/HistoricalLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniHistoricalLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Registration"
              element={
                <Suspense fallback={<Loader />}>
                  <TeamManagerRegister />
                </Suspense>
              }
            />
            <Route
              path="/Alumni/Registration"
              element={
                <Suspense fallback={<Loader />}>
                  <AluminiRegister />
                </Suspense>
              }
            />
            <Route
              path="/Alumni/PlayWithBot"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayWithBot />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ScheduleMatches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniScheduleMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Coaching"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentCoaching />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/CoachingSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/SessionDetails"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentUpcomingCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/CoachingSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/SessionDetails"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniUpcomingCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/MatchDraw"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MatchAlumniDrawResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/MatchDraw"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MatchDrawResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/MatchLeagueDraw"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MatchLeagueDrawResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Sponsor/League/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerLeagueSponsor />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Sponsor/Tournament/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerSponsor />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Notification"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentNotifcationWrap />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Notification"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniNotifcationWrap />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/UpgradeSubscription"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentUpgradeSubscription />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/UpgradeSubscription"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniUpgradeSubscription />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/BotResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <BotResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/BotResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniBotResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveTournaments"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveTournaments />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveLeagues"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveLeagues />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/SendFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <SessionFeedback />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ViewFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewSessionFeedback />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveLeagueDetails/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveLeagueDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveDrawMatch"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveDraw />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Recording/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentRecordChessBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
              <Route
              path="/TeamManager/Recording/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentRecordChessBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Recording/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniRecordChessBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Sponsor/ScheduleDetails/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerScheduleDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Payment/:userType/payment-success/:type/:userId/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <PaymentSuccess />
                </Suspense>
              }
            />
            <Route
              path="/Payment/:userType/payment-failed/:type/:userId/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <PaymentFailed />
                </Suspense>
              }
            />

            <Route
              path="/Student/LiveStreaming/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <LiveBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/LiveStreaming/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniLiveBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveTournamentsDetails/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveTournamentsDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/LiveTournamentsDetails/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniLiveTournamentsDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/SendFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniSendFeedback />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Analytics"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentAnalytics />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Certificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentCertficates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Prizes"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPrizes />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Settings"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentSettings />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Chat"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Chat />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Chat"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Chat />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AllCertificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniAllCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/AllCertificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentAllCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ViewCertificates/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniViewCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ViewCertificates/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentViewCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/OpeningAnalytics"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentOpeningAnalytics />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Analysis"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Analysis />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Records"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Records />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Records"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Student_Records />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Coaches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Coaching />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Certificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Certificate />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Prizes"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Prize />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/PlayWithFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayWithFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AllFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniAllFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/AllAlumni"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentAllAlumni />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AlumniWithAllFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniWithAllFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AlumniWithPlayFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniWithPlayFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AlumniWithMatches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniWithMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AlumniWithResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniWithResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/BotScheduleMatches"
              // path="/Alumni/AlumniWithNewStudents"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <BotScheduleMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="*"
              element={
                <Suspense fallback={<Loader />}>
                  <UserType />
                </Suspense>
              }
            />

            <Route
              path="/language"
              element={
                <Suspense fallback={<Loader />}>
                  <Language />
                </Suspense>
              }
            />

            {/* RegisterRoutes */}
            <Route
              path="/Student/Registration"
              element={
                <Suspense fallback={<Loader />}>
                  <StudentRegister />
                </Suspense>
              }
            />
            <Route
              path="/Student/LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/HistoricalLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentHistoricalLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/HistoricalLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniHistoricalLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Registration"
              element={
                <Suspense fallback={<Loader />}>
                  <TeamManagerRegister />
                </Suspense>
              }
            />
            <Route
              path="/Alumni/Registration"
              element={
                <Suspense fallback={<Loader />}>
                  <AluminiRegister />
                </Suspense>
              }
            />
            <Route
              path="/Alumni/PlayWithBot"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayWithBot />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ScheduleMatches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniScheduleMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Coaching"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentCoaching />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/CoachingSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/SessionDetails"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentUpcomingCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/CoachingSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/SessionDetails"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniUpcomingCoachingSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/MatchDraw"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MatchAlumniDrawResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/MatchDraw"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MatchDrawResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/MatchLeagueDraw"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MatchLeagueDrawResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Sponsor/League/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerLeagueSponsor />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Sponsor/Tournament/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerSponsor />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Notification"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentNotifcationWrap />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Notification"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniNotifcationWrap />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/UpgradeSubscription"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentUpgradeSubscription />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/UpgradeSubscription"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniUpgradeSubscription />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/BotResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <BotResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/BotResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniBotResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveTournaments"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveTournaments />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/LiveTournaments"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniLiveTournaments />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveLeagues"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveLeagues />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/SendFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <SessionFeedback />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveLeagueDetails/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveLeagueDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Student/LiveDrawMatch"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveDraw />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Recording/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentRecordChessBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Recording/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniRecordChessBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LiveStreaming"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <LiveBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Student/LiveTournamentsDetails/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLiveTournamentsDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Analytics"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentAnalytics />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Certificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentCertficates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Prizes"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPrizes />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Settings"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentSettings />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Chat"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Chat />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Chat"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Chat />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AllCertificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniAllCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/AllCertificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentAllCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ViewCertificates/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniViewCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ViewCertificates/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentViewCertificates />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/OpeningAnalytics"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentOpeningAnalytics />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Analysis"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Analysis />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Records"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Records />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Records"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Student_Records />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Coaches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Coaching />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Certificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Certificate />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Prizes"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Alumni_Prize />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/PlayWithFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayWithFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AllFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniAllFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/AllAlumni"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentAllAlumni />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/AllFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentAllFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Profile"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Student_MyProfile />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/PlayWithMatches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayWithMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ScheduleMatches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniStudentPlayWithMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/PlayWithMatches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPlayWithMatches />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/PlayWithResult"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayWitheResult />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/PlayWithResult"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPlayWitheResult />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            {/* OtherAuthRoutes */}

            <Route
              path="/Coach"
              element={
                <Suspense fallback={<Loader />}>
                  <CoachLogin />
                </Suspense>
              }
            />
            <Route
              path="/feedback-form-view"
              element={
                <Suspense fallback={<Loader />}>
                  <FeedbackFormView />
                </Suspense>
              }
            />
            <Route
              path="/Student"
              element={
                <Suspense fallback={<Loader />}>
                  <StudentLogin />
                </Suspense>
              }
            />

            {entities.map((entity) => (
              <React.Fragment key={entity}>
                {/* AboutUs Route */}
                <Route
                  path={`/${entity}/AboutUs`}
                  element={
                    <Suspense fallback={<Loader />}>
                      <About />
                    </Suspense>
                  }
                />

                {/* Terms Route */}
                <Route
                  path={`/${entity}/Terms`}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Terms />
                    </Suspense>
                  }
                />

                {/* Privacy Route */}
                <Route
                  path={`/${entity}/Privacy`}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Privacy />
                    </Suspense>
                  }
                />
              </React.Fragment>
            ))}
            <Route
              path="/TeamManager/Home"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerHome />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/PrizePool"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <PrizePool />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Profile"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerProfile />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/HelpSupport"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/BackupManager"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <BackupManager />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Settings"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Settings />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/club-alumni-members"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManageAlumni />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/PushNotification"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <PushNotification />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/certificates"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CertificatesList />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/View-Certificate/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewCertificate />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/View-recording"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewRecording />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/View-recording"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewRecording />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/View-recording"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewRecording />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/My-Games"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Games />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/View-Tournament"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <GamesTournament />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Upcoming-Games/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <UpcomingGames />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Past-Games/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <PastGames />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Coaches"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachList />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Coach-Booked-Sessions"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachBookedSessions />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Coach-Payment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachPayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/PrizePoolSponsor/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <PrizePoolSponsor />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Sponsor/Tournament/Payment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <SponsorPrizePayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Sponsor/League/Payment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <SponsorLeaguePrizePayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/PrizePool_Tournaments"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <PrizePoolCard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Club-LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ClubLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Alumni-LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ClubLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Club-Tournament"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ClubTournament />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Alumni-Tournament"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ClubTournament />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/StudentRequest"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentRequest />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Club-Payment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Club-Payment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/IndividualSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentIndividual />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/IndividualSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniIndividual />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ViewBookedSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewStudentBookedSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ViewBookedSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewAlumniBookedSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/CoachingPayment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachingPayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/CoachingPayment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniCoachingPayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Profile"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniMyProfile />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Alumni/Settings"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniSettings />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Alumni/Subscription"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniSubscription />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Subscription"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentSubscription />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/MyPayment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniMyPayments />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/MakePayment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MakeAlumniPaymwent />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/MyPayment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentMyPayments />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/MakePayment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MakeStudentPaymwent />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/Student/Notes"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentNots />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/Student/Attendence"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AttendanceList />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/HelpSupport"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/HelpSupport"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/AddFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AddStudentSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/AddFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AddCoachSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/SendFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <SendFeedback />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/ContactUs"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ContactUs />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/AddFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AddAlumniSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/AddFeedback"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerAddSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ChessRating"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniChessRating />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ChessRating"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentChessRating />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/PlayWithFriendChess"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayWithChessBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/PlayWithBotChess"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPlayerBotExtended />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Student/League-Payment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLeaguePayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Student/Play-Bot"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPlayWithBot />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Playing-Bot"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPlayWithBotExtended />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/Prize-League/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPrizeLeagueDistribution />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Prize-League/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPrizeLeagueDistribution />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/Joined-League"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentJoinedLeague />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Joined-League"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerJoinedLeague />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Results"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ResultsDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/LeagueResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <LeagueResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/MatchDraw"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MatchDraw />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Historical-LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <HostoricalLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Tournament_Schedule/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TournamentSchedule />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Alumni/AlumniTournament_Schedule/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniTournamentSchedule />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/StudentTournament_Schedule/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentTournamentSchedule />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/StudentLeague_Schedule/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLeagueSchedule />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/TeamManager_Schedule/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerLeagueSchedule />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Alumni-Club-Memebers"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniClubMembers />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/Club_Tournaments"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Club_Tournaments />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Manager_League_Prize/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerLeaguePrize />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Attendance_Coaching/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerCoachingDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/My_Team"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <MyTeam />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Banner-Details/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <BannerDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/PizePooLeague"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <PizePooLeague />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/LeaguePayment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerLeaguePayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/League"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerLeague />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/LeagueLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamManagerLeagueLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Club_LeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Club_LeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/Club_Payment"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ManagerClubPayment />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/PrizeLeaguePool"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <LeaguePrizePool />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/VideoConference/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <VideoConference />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager/ViewJoined_Tournament"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <ViewJoinedTournament />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/Home"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachHome />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Coach/StudentManagement"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachStudentManagement />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/Profile"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachMyProfile />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/Settings"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachSettings />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/SupportQuery"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachSupportQuery />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/HelpSupport"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachHelpandSupport />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/Schedule"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachSlotManagement />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/SlotManagement"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <IndividualSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/TeamSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <TeamSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/AlumniSession"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniSession />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/AlumniManagement"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniManagement />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Coach/FeedbackManagement"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachFeedbackManagement />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Coach/SchoolManagement"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachSchoolManagement />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Coach/EarningManagement"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachEarningManagement />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Coach/Chat"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <Chat />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Coach/Banner-Details/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <BannerDetails />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Coach/VideoConference/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <VideoConference />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Coach/ChessBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <CoachChessboard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Student/VideoConference/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <VideoConference />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/VideoConference/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <VideoConference />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Student/Home"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentHome />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/PlayWithFriends"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPlayWithFriends />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/PlayWithAlumni"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPlayWithAlumni />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/PlayWithAlumniResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPlayWithAlumniResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/PlayerBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <PlayerBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/PrizeDistribution/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentPrizeDistribution />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/PrizeDistribution/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniPrizeDistribution />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ClubResult"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentClubResult />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ClubResult"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniClubResult />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ClubLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentClubLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ClubLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniClubLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/ClubTournament"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentClubTournament />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/LeagueResults"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLeagueResults />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/TeamManager/LeaguePrizePool/:id"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <LeaguePrizePoolSponsor />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Student/ViewJoined"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentViewJoined />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ViewJoined"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniViewJoined />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/CityLeague"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLeague />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Student/CityLeaderBoard"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <StudentLeagueLeaderBoard />
                  </Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/Alumni/Home"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AluminiHome />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Alumni/ClubTournament"
              element={
                <ProtectedRoutes>
                  <Suspense fallback={<Loader />}>
                    <AlumniTornament />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/TeamManager"
              element={
                <Suspense fallback={<Loader />}>
                  <TeamManagerLogin />
                </Suspense>
              }
            />

            <Route
              path="/Alumni"
              element={
                <Suspense fallback={<Loader />}>
                  <AluminiLogin />
                </Suspense>
              }
            />
          </Routes>
        </UserAuthContextProvider>
      </Router>
    </div>
  );
}

export default App;
